import s from './app.scss';
import 'whatwg-fetch';

(async function() {
  const headers = new Headers({
    Accept: 'application/json',
    Cookie: document.cookie,
  });

  const translations = require(`./assets/locale/messages/messages_en.json`); //config.language
  const logoImage = require(`./assets/wix-developers.svg`);
  const dropdownArrow = require(`./assets/dropdown-arrow.svg`);
  const baseUrl = window.__BASEURL__
    ? window.__BASEURL__
    : 'https://dev.wix.com/dc3/';

  const links = {
    home: '/',
    myApps: `${baseUrl}my-apps/`,
    businessAccount: `${baseUrl}business-account/`,
    docs: 'https://dev.wix.com/docs/',
    support: 'https://devforum.wix.com/en/',
    market: 'https://www.wix.com/app-market/main',
    signin:
      'https://users.wix.com/signin?loginDialogContext=login&referralInfo=HEADER&postLogin=https:%2F%2Fdev.wix.com%2Fdc3%2Fmy-apps&postSignUp=https:%2F%2Fdev.wix.com%2Fdc3%2Fmy-apps',
    developerAccount:
      'https://dev.wix.com/apps/developer-account/developer-info',
    user: `${baseUrl}api/user-details`,
    logout: 'https://www.wix.com/signout?redirectTo=https://dev.wix.com',
    oldDevcenter: 'https://dev.wix.com/_api/dev-center-server/v1/apps',
    BusinessAccountExperiment:
      'https://www.wix.com/_api/wix-laboratory-server/laboratory/conductExperiment?key=specs.devCenter.ShowBusinessPageInHeader&fallback=false',
  };

  const defaultUserDetails = {
    photo:
      'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiI+CiAgICA8ZGVmcz4KICAgICAgICA8cmVjdCBpZD0iYSIgd2lkdGg9IjM2IiBoZWlnaHQ9IjM2IiByeD0iMTgiLz4KICAgIDwvZGVmcz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPgogICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNhIi8+CiAgICAgICAgPC9tYXNrPgogICAgICAgIDx1c2UgZmlsbD0iI0RBRUZGRSIgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNCMUQ5RjciIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTMwIDM1YzAtNS4xMy0zLjIxOC05LjUwNy03Ljc0NS0xMS4yMjRhOCA4IDAgMSAwLTguNTEgMEM5LjIxOCAyNS40OTMgNiAyOS44NzEgNiAzNXYxaDI0di0xeiIgbWFzaz0idXJsKCNiKSIvPgogICAgPC9nPgo8L3N2Zz4K',
    userName: 'Unavailable',
    first: '',
    last: '',
  };

  function getCookie(name) {
    const cookie = document.cookie.match(
      new RegExp('(^|;\\s*)' + name + '=([^;]*)'),
    );
    return cookie && cookie[2];
  }

  async function getOldAppsFromDevCenter() {
    return await fetch(links.oldDevcenter, { headers, credentials: 'include' })
      .then(response => response.json())
      .catch(() => []);
  }

  async function getUser() {
    if (baseUrl.includes('localhost')) {
      return Promise.resolve(defaultUserDetails);
    }

    return await fetch(links.user, { headers, credentials: 'include' })
      .then(response => response.json())
      .then(data => data.user)
      .catch(() => defaultUserDetails);
  }

  async function shouldShowBusinessAccount() {
    return await fetch(links.BusinessAccountExperiment, {
      headers,
      credentials: 'include',
    })
      .then(response => response.json())
      .catch(() => false);
  }

  async function getLoggedInMarkup() {
    const oldApps = await getOldAppsFromDevCenter();
    const allowBusinessAccount = await shouldShowBusinessAccount();
    const user = await getUser();
    const profileImage = user.photo;
    const userName =
      user.first !== null && user.last !== null
        ? `${user.first} ${user.last}`
        : user.userName;
    return `<label for="userDetails">
        <ul class=${s.userDetails}>
            <li class=${s.userInfo} ><img class=${
      s.profileImage
    } src=${profileImage} /><div class=${s.userNameContainer}><span class=${
      s.userName
    } >${userName}</span><span class=${
      s.navigationArrow
    }>&rsaquo;</span></div></li>
            <li class=${s.userActionWrapper}>
                <input type="checkbox" id="userDetails" class=${s.toggle} />
                <ul class=${s.userAction}>
                    ${
                      oldApps.length !== 0
                        ? `<li><a href=${links.developerAccount}>${
                            translations['menu_developer_account']
                          }</a></li>`
                        : ''
                    }   
                    ${
                      allowBusinessAccount
                        ? `<li><a href=${links.businessAccount}>${
                            translations['menu_business_account']
                          }</a></li>`
                        : ''
                    }   
                    <li><a href=${links.logout}>${
      translations['logout']
    }</a></li>    
                </ul>
            </li>
        </ul></label>`;
  }

  async function renderMarkup() {
    return `
<a class=${s.logoWrapper} href=${links.home}><img class=${
      s.logo
    } src=${logoImage}/></a>
        <input type="checkbox" id="mobileMenuToggle" class=${
          s.mobileMenuToggle
        } />
        <label class=${s.mobileTrigger} for="mobileMenuToggle" class=${
      s.mobileIcon
    }>
            <span class=${s.stripe} ></span>
            <span class=${s.stripe}></span>
            <span class=${s.stripe}></span>
        </label>
<nav class=${s.navigation}>
                    <ul class=${s.navigationLinks}>
                        
                        <li class=${
                          s.navLink
                        }><a data-selected="${links.myApps ===
      location.pathname}" href=${links.myApps}>${
      translations['menu_my_apps']
    }</a></li>
                        <li class=${
                          s.navLink
                        }><a data-selected="${links.docs ===
      location.pathname}" href=${links.docs} target="_blank" >${
      translations['menu_docs']
    }</a></li>
                        <li class=${
                          s.navLink
                        }><a data-selected="${links.support ===
      location.pathname}" href=${links.support} target="_blank" >${
      translations['menu_support']
    }</a></li>
                        <li class=${
                          s.navLink
                        }><a data-selected="${links.market ===
      location.pathname}" href=${links.market} target="_blank" >${
      translations['menu_app_market']
    }</a></li>
                    </ul>
                    <div class=${s.userDetailsMenu} id="dc-user-details">
                        
                    </div>
                </nav>`;
  }

  let header = document.getElementsByTagName('header');
  const firstBodyChild = document.getElementsByTagName('body')[0].children[0];
  if (header.length === 0) {
    header = document.createElement('header');
  } else {
    header = header[0];
  }
  header.setAttribute('data-hook', 'new-header');
  header.innerHTML = await renderMarkup();
  header.classList.add(s.header);
  document.getElementsByTagName('body')[0].insertBefore(header, firstBodyChild);
  const userDetails = document.getElementById('dc-user-details');
  if (getCookie('wixClient')) {
    const loggedState = await getLoggedInMarkup();
    userDetails.innerHTML = loggedState;
  } else {
    userDetails.setAttribute(
      'style',
      'display: flex; align-items: center; justify-content: center;',
    );
    userDetails.innerHTML = `<a class=${s.signIn} href=${links.signin}>${
      translations['signin']
    }</a>`;
  }
})();
